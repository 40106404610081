import * as t from 'io-ts'
import { OdometerType } from '@carsys/enums/odometer-type'
import { ProposalSource } from '@cbgms/api/modules/enums/proposal-source'
import { CountryCodeRTT, ShopID, UUID } from '@appointment-planner/api/types'

const ProposalSourceRRT = t.keyof<{ [key in ProposalSource]: null }>(
  {
    [ProposalSource.Planner]: null,
    [ProposalSource.FIFM]: null,
    [ProposalSource.VMN_NL]: null,
    [ProposalSource.VMN_BE]: null,
    [ProposalSource.VMN_FR]: null,
    [ProposalSource.Vakgarage_NL]: null,
    [ProposalSource.BudgetService_NL]: null,
    [ProposalSource.AutoFirst_NL]: null,
    [ProposalSource.AutoProfijt_NL]: null,
    [ProposalSource.Mongarage_BE]: null,
    [ProposalSource.Carteam_NL]: null,
    [ProposalSource.OneTec]: null,
    [ProposalSource.Other]: null
  },
  'ProposalSource'
)

const OdometerUnitRTT = t.keyof<{ [key in OdometerType]: null }>(
  {
    [OdometerType.Hours]: null,
    [OdometerType.Kilometers]: null,
    [OdometerType.Miles]: null,
    [OdometerType.NoOdometer]: null
  },
  'OdometerUnit'
)

export const Appointment = t.interface(
  {
    ActivityUUIDs: t.array(UUID),
    ActivityRemark: t.string,
    City: t.string,
    Date: t.string,
    EmailAddress: t.string,
    FirstName: t.string,
    HouseNumber: t.string,
    HouseNumberAddition: t.string,
    Name: t.string,
    NamePrefix: t.string,
    Odometer: t.number,
    OdometerUnit: OdometerUnitRTT,
    PhoneNumber: t.string,
    PostalCode: t.string,
    Registration: t.string,
    RegistrationCountry: CountryCodeRTT,
    Remark: t.string,
    ShopID,
    Street: t.string,
    Time: t.string,
    Title: t.string,
    Source: ProposalSourceRRT
  },
  'Appointment'
)

export type Appointment = t.TypeOf<typeof Appointment>
